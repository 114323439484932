.slp-container {
  color: #cfcfcf;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slp-pop-cont {
  background: linear-gradient(to right, #3b3b3b 20%, #515151);
  background-size: 400% 400%;
  animation: gradient 30s ease infinite;
  height: 3rem;
  width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 0.4rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  box-shadow: 2px 2px 20px 2px #222124;
  font-size: large;
  border: #dddddd 1px solid;
}
@media screen and (max-width: 800px) {
  .slp-pop-cont {
    width: 20rem;
    height: 5rem;
  }
}
.slp-pop-cont:hover {
  cursor: pointer;
}

.sltc-week-number-input {
  width: 3rem;
  height: 1.5rem;
  border-radius: 0.2rem;
  border: none;
  background-color: #29272b;
  color: #cfcfcf;
  text-align: center;
  margin-left: 0.2rem;
  margin-right: 0.5rem;
}

.sltc-week-number-label {
  color: #cfcfcf;
  font-size: 0.8rem;
  margin-left: 2rem;
  margin-right: 0rem;
}

.sltc-week-number-beta-tag {
  color: #cfcfcf;
  font-size: 0.6rem;
  margin-left: -1rem;
  margin-top: -1.3rem;
}

.slp-title {
  margin-top: 2rem;
  color: #cfcfcf;
  font-size: 1.6rem;
}

.slp-track-names {
  margin-bottom: 1rem;
  color: #cfcfcf;
  font-size: 0.8rem;
}

.slp-series-squares-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 0.4rem;
}
@media screen and (max-width: 1000px) {
  .slp-series-squares-container {
    width: 30rem;
    gap: 0.5rem;
  }
}

.data-table-container-sp {
  margin: 2% 6% 2% 6%;
}
.data-table-container-sp tr {
  height: 1.2rem;
  padding: 0rem;
  margin: 0rem;
  border-left: none;
}
.data-table-container-sp td {
  padding-left: 1rem;
  line-height: 1.2rem;
}
@media screen and (max-width: 800px) {
  .data-table-container-sp {
    margin: 2%;
  }
}

.slp-season-name {
  color: #cfcfcf;
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 0rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media screen and (max-width: 800px) {
  .slp-season-name {
    margin-bottom: 0.5rem;
    padding-bottom: 0px;
    font-size: 1rem;
  }
}

.sltc-table-selection-title {
  color: #cfcfcf;
  font-size: 0.9rem;
  border-bottom: #f1c40f 1px solid;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.sltc-table-selection-title-inactive {
  color: #cfcfcf;
  font-size: 0.9rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  background: linear-gradient(to right, rgba(56, 56, 56, 0.4941176471) 20%, rgba(62, 62, 62, 0.4784313725));
  border: 1px solid #616161;
  border-radius: 0.2rem;
}

.sltc-button-choice-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sltc-button-choice-cont .sltc-choice-active {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  padding: 0rem 0.4rem 0rem 0.4rem;
  color: #cfcfcf;
  background: linear-gradient(to right, #434343 20%, #3d3d3d);
  border-radius: 0.3rem;
  border: none;
}
.sltc-button-choice-cont .sltc-choice-active:hover {
  cursor: pointer;
  color: #f1c40f;
  transform: scale(1.01);
  transition: all 0.2s ease-in-out;
}
.sltc-button-choice-cont .sltc-choice-inactive {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  padding: 0rem 0.3rem 0rem 0.3rem;
  color: #cfcfcf;
  background-color: #29272b;
  border-radius: 0.3rem;
  border: none;
}
.sltc-button-choice-cont .sltc-choice-inactive:hover {
  cursor: pointer;
  color: #f1c40f;
  transform: scale(1.01);
  transition: all 0.2s ease-in-out;
}

.centering-div-pure {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.centering-div-pure-sel-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #505050;
  min-width: 70vw;
}

.table-toggle-button {
  background: linear-gradient(to right, rgba(56, 56, 56, 0.4941176471) 20%, rgba(62, 62, 62, 0.4784313725));
  color: #cfcfcf;
  border: 1px solid #616161;
  border-radius: 0rem;
  padding: 0.2rem 1rem 0.25rem 1rem;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  width: 80vw;
  border-radius: 0.2rem;
}
.table-toggle-button:hover {
  cursor: pointer;
  background: linear-gradient(to right, #505050 20%, #5f5f5f);
  color: #f1c40f;
  transform: scale(1.01);
  transition: all 0.2s ease-in-out;
}

.navigate-to-multiclass {
  color: #cfcfcf;
  font-size: 1.2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-decoration: underline;
}
.navigate-to-multiclass:hover {
  cursor: pointer;
}

.slp-series-square {
  width: 30rem;
  height: 2rem;
  color: #cfcfcf;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.2rem;
  border: 1px solid #616161;
  padding: 0.3rem 4rem 0.3rem 2rem;
  background: linear-gradient(to right, #3d3d3d 20%, #313131);
  box-shadow: #262529 2px 2px 20px 2px;
}
@media screen and (max-width: 800px) {
  .slp-series-square {
    width: 90vw;
    padding: 0.3rem 2rem 0.3rem 1rem;
  }
}
.slp-series-square .slp-series-name {
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.7rem;
}
.slp-series-square:hover {
  cursor: pointer;
  background: linear-gradient(to right, #154fa7 20%, #12428a);
  color: #f1c40f;
  transform: scale(1.05);
  transition: all 0.1s ease-in-out;
}

.slp-or-div {
  width: auto;
  height: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  font-size: large;
}

.search-bar-container-slt .search-bar {
  border-radius: 0rem;
  border: none;
  border-bottom: #cfcfcf 1px solid;
  color: #cfcfcf;
  margin-bottom: 1rem;
  background-color: rgba(207, 207, 207, 0);
}/*# sourceMappingURL=SeriesLapTimeChart.css.map */